import { useAuth0 } from '@auth0/auth0-react';
import { persistor } from '../App';
import { logoutChannel } from '../authentication/AutoLogoutWrapper';

export const useLogout = (to = '') => {
  const { logout: auth0Logout } = useAuth0();
  const handleLogout = (_, manualPath) => {
    sessionStorage.clear();
    localStorage.clear();
    persistor
      .purge()
      .catch((err) => console.log('state purge error: ', err));

    try {
      auth0Logout({
        returnTo: `${window.location.origin}/${manualPath ?? to}`,
      });
      // Send message in channel to force logout from other opened tabs
      logoutChannel.postMessage({ logoutMessage: 'Force logout!' });
    } catch (error) {
      console.log('Error while logging out: ', error);
    }
  };
  return handleLogout;
};