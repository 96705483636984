import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { withStudyArmRolePermissions } from 'hocs/withStudyArmRolePermissions';

import { withPerson } from 'authentication/withPerson';
import { useSelector, useDispatch } from 'ihp-bloom-redux/app/redux';
import { setValues } from 'ihp-bloom-redux/features/user/userSlice';
import { accountProfileApiSlice } from 'ihp-bloom-redux/features/profile/accountProfileApiSlice';
import { produce } from 'immer';

import { withPersonAttributes } from 'hocs/withPersonAttributes';
import useScrollToTop from '../../../hooks/useScrollToTop';
import OnboardingLayout from '../components/Layout';
import ResearchEligibilityRoleForm from './ResearchEligibilityRoleForm';
import { useEnrollParticipant } from './hooks/useEnrollParticipant';
import getRoute from 'utils/navigator';
import { useEnrollLar } from './hooks/useEnrollLar';
import ParticipantInformationForm from '../ParticipantInformation/ParticipantInformationForm';
import { Loader } from 'components/Loader';
import {
  STUDY_ARM_ROLE_NAME,
} from 'constants/global';
import {
  useUpdatePersonStudyArmMutation,
} from 'ihp-bloom-redux/features/personStudyArm/personStudyArmApiSlice';
import { useConfiguration } from './hooks/useConfiguration';
import { formatDateToDB } from 'utils/date';
import { isAboveStateAgeMajority } from '../BasicInformation/helper';
import { useEnrollContact } from './hooks/useEnrollContact';
import withPersonStatusCheck from 'utils/withPersonStatusCheck';

function calculateAge(dob) {
  const today = new Date();
  const birthDate = new Date(dob);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();
  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  return age;
}

const getStudyArmRoleCode = (role, dateOfBirth, state) => {
  const age = calculateAge(dateOfBirth);
  let ageGroup = '';
  if (age < 7) {
    ageGroup = 'under_7';
  } else if (isAboveStateAgeMajority(dateOfBirth, state)) {
    ageGroup = 'adult';
  } else {
    ageGroup = '7_to_majority';
  }
  return `${role}_${ageGroup}`;
};

const larParentRoles = ['r_parent_sv', 'r_parent_nsv', 'r_lar_sv', 'r_lar_nsv'];

function ResearchEligibilityRole({ person }) {
  document.title = 'Single Ventricle SOURCE Research Eligibility Role';

  useScrollToTop();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const { activeAccountProfile, personAttributes } = user;

  // SING-580
  // const [enrollParticipant] = useEnrollParticipant();
  const [enrollParticipant, _, error] = useEnrollParticipant();
  const [enrollLar] = useEnrollLar();
  const [enrollContact] = useEnrollContact();

  const activeProfile = activeAccountProfile.attributes.profile;
  const personStudyArmId = activeProfile.account_person_study_arm_id;

  const [showDiagosedProfileScreen, setShowDiagosedProfileScreen] =
    useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [showFakeLoader, setShowFakeLoader] = useState(false);
  const { studyArmRoles, studyArmStatuses, getContactStudyArm } =
    useConfiguration();
  const [updatePersonStudyArm] = useUpdatePersonStudyArmMutation();
  const [lazyFetchAccountProfile] =
    accountProfileApiSlice.endpoints.getAccountProfile.useLazyQuery();

  const { isEnrollingNewPerson } = useSelector((s) => s.user);
  const handleSubmit = async (selectedRole) => {
    try {
      let updatedUser = null;
      if (larParentRoles.includes(selectedRole)) {
        setSelectedRole(selectedRole);
        setShowFakeLoader(true);
        setTimeout(() => setShowFakeLoader(false), 1000);
        setShowDiagosedProfileScreen(true);
      } else {
        if (selectedRole === 'none') {
          if (!isEnrollingNewPerson) {
            await enrollContact(user);
          }
          // Update user to
          return navigate('no-role-applicable', {
            state: { type: STUDY_ARM_ROLE_NAME.NO_RELATIONSHIPS },
          });
        }
        if(isEnrollingNewPerson){
          updatedUser = await enrollParticipant({
            user,
            studyArmRoleCode: selectedRole,
          });
          dispatch(setValues(updatedUser));
          navigate(getRoute(updatedUser));
        }else{
          let updatedProfile = await lazyFetchAccountProfile(
            activeAccountProfile.attributes.profile.id
          ).unwrap();
          const updatedStudyArmStatusTitle = updatedProfile?.data?.attributes?.profile?.study_arm_status_title;
          if (!['enrollment-role-selected', 'enrollment-personal-info-submitted', 'enrollment-consented', 'enrolled'].includes(updatedStudyArmStatusTitle)) {
            updatedUser = await enrollParticipant({
              user,
              studyArmRoleCode: selectedRole,
            });
            dispatch(setValues(updatedUser));
            navigate(getRoute(updatedUser));
          }else{
            updatedUser = produce(user, (draft) => {
              draft.activeAccountProfile = updatedProfile.data;
            });
            dispatch(setValues(updatedUser));
            navigate(getRoute(updatedUser));
          }
        }
      }
    } catch (err) {
      console.log('enrollment error:', err);
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  const onSubmitParticipantInfo = async (data) => {
    if (!larParentRoles.includes(selectedRole)) {
      throw new Error('Unknown role');
    }

    // Format date of birth to YYYY-MM-DD
    const formData = {
      ...data,
      date_of_birth: formatDateToDB(data.date_of_birth),
    };
    const studyArmRoleCode = getStudyArmRoleCode(
      selectedRole,
      data.date_of_birth,
      personAttributes.state
    );
    let updatedUser;
    if(isEnrollingNewPerson){
      updatedUser = await enrollLar({
        user,
        studyArmRoleCode,
        subjectPersonAttributes: formData,
      });
      dispatch(setValues(updatedUser));
      navigate(getRoute(updatedUser));
    }else{
      let updatedProfile = await lazyFetchAccountProfile(
        activeAccountProfile.attributes.profile.id
      ).unwrap();
      const updatedStudyArmStatusTitle = updatedProfile?.data?.attributes?.profile?.study_arm_status_title;
        if (!['enrollment-role-selected', 'enrollment-personal-info-submitted', 'enrollment-consented', 'enrolled'].includes(updatedStudyArmStatusTitle)) {
          updatedUser = await enrollLar({
            user,
            studyArmRoleCode,
            subjectPersonAttributes: formData,
          });
          dispatch(setValues(updatedUser));
          navigate(getRoute(updatedUser));
        }else{
          updatedUser = produce(user, (draft) => {
            draft.activeAccountProfile = updatedProfile.data;
          });
          dispatch(setValues(updatedUser));
          navigate(getRoute(updatedUser));
        }
    }
    

    
  };

  let content = null;
  if (showFakeLoader) {
    content = <Loader />;
  } else if (showDiagosedProfileScreen) {
    content = (
      <ParticipantInformationForm
        initialState={{
          date_of_birth: '',
        }}
        onSubmit={onSubmitParticipantInfo}
        role={selectedRole}
      />
    );
  } else {
    // SING-580
    // content = <ResearchEligibilityRoleForm onSubmit={handleSubmit} />;
    content = (
      <ResearchEligibilityRoleForm onSubmit={handleSubmit} error={error} />
    );
  }

  return (
    <OnboardingLayout maxWidth='500px' backHandler={goBack}>
      {content}
    </OnboardingLayout>
  );
}

export default withPersonStatusCheck(withPerson(
  withPersonAttributes(withStudyArmRolePermissions(ResearchEligibilityRole))
));
