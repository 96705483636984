import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';

import { ACMESteady } from './ACMESteady';
// import { GetStarted } from './GetStarted';
import { getAuth0LoginPageConfig } from 'utils/auth0';
import { Loader } from 'components/Loader';
import { participantStatus } from 'utils/authenticate';
import { useLogout } from 'utils/useLogout';
import { Hero } from './Header/Hero';
import { useAuth0Extended } from 'hooks/useAuth0Extended.js';
import { persistor } from 'App.js';
import { useNavigate } from 'react-router-dom';

const Welcome = () => {
  document.title = 'Single Ventricle SOURCE';
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const search = useLocation().search;
  const queryParams = new URLSearchParams(search);
  const openAuth0 =
    queryParams.get('open-auth0') && queryParams.get('open-auth0') !== null
      ? queryParams.get('open-auth0')
      : undefined;
  const personStatus =
    queryParams.get('person-status') &&
    queryParams.get('person-status') !== null
      ? queryParams.get('person-status')
      : undefined;
  const allowReEnroll =
    queryParams.get('allow-re-enroll') &&
    queryParams.get('allow-re-enroll') !== null
      ? queryParams.get('allow-re-enroll')
      : undefined;

  const logout = useLogout();
  const { loginWithRedirect, routeIsAuthenticated, isLoading } =
    useAuth0Extended();
  const { person } = useSelector((state) => state.user);
  // const { isAuthenticated } = useSelector((state) => state.authentication);

  const openSignupPage = (context) => {
    loginWithRedirect(
      getAuth0LoginPageConfig({
        context,
        'person-status': personStatus,
        'allow-re-enroll': allowReEnroll,
      })
    );
  };

  // const navigateToWelcomeToStudy = () => {
  //   navigate('/onboarding/welcome-to-study');
  // };

  const handleBackToHome = async () => {
    logout();
  };

  //   useEffect(() => {
  //     if (isAuthenticated) {
  //       return navigate('/auth0-callback');
  //     }
  //   }, [navigate, isAuthenticated]);

  useEffect(() => {
    const auth0ValidContexts = ['signin', 'signup'];

    if (openAuth0 === 'force-signup') {
      handleBackToHome();
    }

    if (auth0ValidContexts.includes(openAuth0)) {
      openSignupPage(openAuth0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openAuth0]);

  useEffect(() => {
    const process = async () => {
      if (routeIsAuthenticated) {
        return navigate('/auth0-callback');
      } else if (person?.id && !isLoading && !routeIsAuthenticated) {
        /* Make sure to clear local storage if user visits this page and session is expired */
        await sessionStorage.clear();
        await localStorage.clear();
        await persistor.purge();
      }
    };

    process();
  }, [navigate, routeIsAuthenticated]);

  /*
    Display loader when person status is 'withdrawn' because app is in process of loading auth0 login page.
    We need to display this to avoid welcome page blink during navigation 
   */
  if (
    (openAuth0 === 'signin' && personStatus === participantStatus.WITHDRAWN) ||
    openAuth0 === 'signup' ||
    isLoading
  ) {
    return <Loader />;
  }

  return (
    <>
      <Hero />
      <ACMESteady />

      {/* <GetStarted onGetStarted={navigateToWelcomeToStudy} /> */}
    </>
  );
};
export default Welcome;
