import { Box, Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useSelector, useDispatch } from 'ihp-bloom-redux/app/redux';
import {
  useEditPersonAttributesMutation,
  profileAttributesApiSlice,
} from 'ihp-bloom-redux/features/profile/profileAttributesApiSlice';
import { useUpdatePersonStudyArmMutation } from 'ihp-bloom-redux/features/personStudyArm/personStudyArmApiSlice';
import { setValues } from 'ihp-bloom-redux/features/user/userSlice';

import Button from 'ihp-components/components/v2/Button';
import { Loader } from 'components/Loader';

import { withPersonAttributes } from 'hocs/withPersonAttributes';
import { Title, Text } from 'pages/Onboarding/components/Text';

import OnboardingLayout from '../components/Layout';
import { ReactComponent as AVLogo } from 'images/svs-logo.svg';

import {
  LANG,
  getPersonInformation,
  generatePersonInformationFieldsMap,
  FIELD_TYPE_RENDERERS_MAP,
} from 'utils/configParseUtils';

import {
  STUDY_ARM_CONFIG_VARIABLES,
  STUDY_ARM_STATUS_CODES,
  STUDY_ARM_STATUS_NAMES,
} from 'constants/global';

import { ButtonContainer, Row } from './styles';
import getRoute, { routes } from 'utils/navigator';
import { withStudyArmRolePermissions } from 'hocs/withStudyArmRolePermissions';
import useScrollToTop from '../../../hooks/useScrollToTop';
import { removeSpecialCharactersFromPhone } from '../../../utils/formValidation';
import { useEffect, useState, useCallback } from 'react';
import { useConfiguration } from '../ResearchEligibilityRole/hooks/useConfiguration';
import { updatePersonStudyArmPayload } from '../ResearchEligibilityRole/hooks/payloads';
import { produce } from 'immer';
import { formatDateToDB, parseDate } from 'utils/date';
import { accountProfileApiSlice } from 'ihp-bloom-redux/features/profile/accountProfileApiSlice';

const useSetPersonalInformationStatus = () => {
  const [updatePersonStudyArm, state] = useUpdatePersonStudyArmMutation();
  const { studyArmStatuses } = useConfiguration();
  const user = useSelector((state) => state.user);
  const profile = user.activeAccountProfile.attributes.profile;

  const dispatch = useDispatch();
  const trigger = useCallback(async () => {
    const {
      study_arm_id: studyArmId,
      account_person_study_arm_id: personStudyArmId,
    } = profile;
    const personalInfoSubmitedStatus = studyArmStatuses.find(
      (status) =>
        status.title ===
          STUDY_ARM_STATUS_NAMES['ENROLLMENT-PERSONAL-INFO-SUBMITTED'] &&
        status.study_arm_id === studyArmId
    );

    await updatePersonStudyArm({
      personStudyArmId,
      payload: updatePersonStudyArmPayload({
        personStudyArmId,
        studyArmStatusId: personalInfoSubmitedStatus.id,
      }),
    }).unwrap();

    const updatedUser = produce(user, (draft) => {
      const profile = draft.activeAccountProfile.attributes.profile;
      profile.study_arm_status_id = personalInfoSubmitedStatus.id;
      profile.study_arm_status_title =
        STUDY_ARM_STATUS_NAMES['ENROLLMENT-PERSONAL-INFO-SUBMITTED'];
    });
    dispatch(setValues(updatedUser));
    return updatedUser;
  }, [dispatch, profile, studyArmStatuses, updatePersonStudyArm, user]);

  return [trigger, state];
};

const withShouldSeePersonalInformationScreen =
  (WrappedComponent) =>
  ({ personAttributes, ...rest }) => {
    const dispatch = useDispatch();
    const [status, setStatus] = useState('INITIAL');

    const user = useSelector((state) => state.user);

    console.log(user);

    const { participantProfileConfig } = useSelector(
      (state) => state.configuration
    );
    const personId = user.person.id;

    console.log(participantProfileConfig);

    const [setStatusSubmitedPersonalInformation] =
      useSetPersonalInformationStatus();

    const navigate = useNavigate();

    useEffect(() => {
      const checkPersonalInfoSubmitted = async () => {
        const studyArmStatusTitle =
          user.activeAccountProfile.attributes.profile.study_arm_status_title;
        console.log(studyArmStatusTitle);
        if (status !== 'INITIAL' || !personAttributes) {
          return;
        }

        // if (
        //   studyArmStatusTitle ===
        //   STUDY_ARM_STATUS_NAMES['ENROLLMENT-PERSONAL-INFO-SUBMITTED']
        // ) {
        //   setStatus(() => 'COMPLETE');
        //   return;
        // }
        setStatus(() => 'LOADING');

        const expectedRoute = getRoute(user);
        console.log('expectedRoute: ', expectedRoute);

        if (expectedRoute === routes['PERSONAL-INFO']) {
          console.log('personAttributes:', personAttributes);
          const { fields } = getPersonInformation(participantProfileConfig);
          const requiredFields = fields.filter(({ required }) => required);
          const hasSubmittedAllAttributes = requiredFields.every(
            ({ key }) => key in personAttributes && personAttributes[key] !== ''
          );
          console.log('hasSubmittedAllAttributes', hasSubmittedAllAttributes);
          if (hasSubmittedAllAttributes) {
            console.log('navigating to consent');
            await setStatusSubmitedPersonalInformation();

            await new Promise((resolve) => setTimeout(resolve, 4000));
            setStatus(() => 'REDIRECTING');
            await dispatch({
              type: `${accountProfileApiSlice.reducerPath}/invalidateTags`,
              payload: ['AccountProfiles', 'AccountProfile'],
            });
            return navigate(routes.CONSENTS);
          }
        }
        setStatus(() => 'COMPLETE');
      };
      checkPersonalInfoSubmitted();
      return () => {
        console.log('unmounitng: withShouldSeePersonalInformationScreen');
      };
    }, [
      user,
      personAttributes,
      status,
      setStatusSubmitedPersonalInformation,
      participantProfileConfig,
      navigate,
    ]);

    /**
     * condition 1) status !== complete means we're still loading,
     * condition 2) getRoute returned consents routes that means we're in the process of redirection
     *              but seeing this due to messy mounting and *remounting of the component
     */
    if (status !== 'COMPLETE') {
      return <Loader />;
    }

    const expectedRoute = getRoute(user);
    console.log(
      'Rendering personal information: ',
      getPersonInformation(participantProfileConfig),
      expectedRoute
    );

    return <WrappedComponent personAttributes={personAttributes} {...rest} />;
  };

function PersonalInformationDetails(props) {
  document.title = 'Single Ventricle SOURCE Add Personal Information';

  useScrollToTop();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const { participantProfileConfig, configuration } = useSelector(
    (state) => state.configuration
  );
  const { activeAccountProfile, person, isEnrollingNewPerson } = useSelector((state) => state.user);
  const countryName =
    activeAccountProfile?.attributes?.person?.attributes?.country;
  const [lazyFetchAccountProfile] = accountProfileApiSlice.endpoints.getAccountProfile.useLazyQuery();

  const { meta, fields } = getPersonInformation(participantProfileConfig);
  const fieldsMap = generatePersonInformationFieldsMap(fields, countryName);

  // available fields list from configuration
  const availableFields = fieldsMap.map((field) => {
    if (field.show) {
      return field.name;
    }
  });
  const initialValueAttributes = {};
  Object.keys(props.personAttributes || {})?.forEach((key) => {
    if (availableFields?.includes(key)) {
      if (key === 'date_of_birth') {
        initialValueAttributes[key] = parseDate(props.personAttributes[key]);
      } else {
        initialValueAttributes[key] = props.personAttributes[key];
      }
    }
  });

  if (!initialValueAttributes.phone) {
    initialValueAttributes.phone = '+';
  }

  const [editPersonAttributes, { isLoading: isEditingPersonAttributes }] =
    useEditPersonAttributesMutation();
  const [updatePersonStudyArm, { isLoading: isUpdatingStudyArm }] =
    useUpdatePersonStudyArmMutation();

  const researchEnrollmentPersonalInfoSubmitted =
    configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_STATUSES][
      STUDY_ARM_STATUS_CODES.RESEARCH_ENROLLMENT_PERSONAL_INFO_SUBMITTED
    ];
  const person_study_arm_id =
    activeAccountProfile?.attributes?.profile?.account_person_study_arm_id;

  const currentRole =
    activeAccountProfile?.attributes?.profile?.study_arm_role_name?.toLowerCase();

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm({
    defaultValues: { ...initialValueAttributes },
  });

  const goBack = () => {
    navigate(-1);
  };

  const personStudyArmPayload = {
    data: {
      type: 'person-study-arms',
      id: `${person_study_arm_id}`,
      attributes: {
        study_arm_status_id: parseInt(
          researchEnrollmentPersonalInfoSubmitted?.id
        ),
      },
    },
  };

  const onSubmit = async (data) => {
    if(!isEnrollingNewPerson){
      const updatedProfile = await lazyFetchAccountProfile(
        activeAccountProfile.attributes.profile.id
      ).unwrap();
      const updatedStudyArmStatusTitle = updatedProfile?.data?.attributes?.profile?.study_arm_status_title;
      if(['enrollment-personal-info-submitted', 'enrollment-consented', 'enrolled'].includes(updatedStudyArmStatusTitle)){
        const updatedUser = produce(user, (draft) => {
          draft.activeAccountProfile = updatedProfile.data;
        });
        dispatch(setValues(updatedUser));
        navigate(getRoute(updatedUser));
        return;
      }
    }
    data.phone = removeSpecialCharactersFromPhone(data.phone);
    if (data.phone === '' || data.phone === '+') {
      delete data.phone;
    } else {
      data.phone = data.phone.replace(/[()-\s]/g, '');
    }

    // Convert date_of_birth to date only format
    if (data.date_of_birth) {
      data.date_of_birth = formatDateToDB(data.date_of_birth);
    }

    let payload = [];
    for (let key in data) {
      if (data[key]) {
        const obj = {
          data: {
            type: 'person-attributes',
            attributes: {
              attribute: key,
              value: data[key],
            },
          },
        };
        payload = [...payload, obj];
      }
    }

    try {
      const personAttributesResponse = await editPersonAttributes({
        personId: person.id,
        payload: payload,
      });
      if (!personAttributesResponse.error) {
        const personStudyArmRes = await updatePersonStudyArm({
          personStudyArmId: person_study_arm_id,
          payload: personStudyArmPayload,
        });
        if (!personStudyArmRes.error) {
          const newState = {
            ...user,
            activeAccountProfile: {
              ...user.activeAccountProfile,
              attributes: {
                ...user.activeAccountProfile.attributes,
                profile: {
                  ...user.activeAccountProfile.attributes.profile,
                  study_arm_status_id:
                    researchEnrollmentPersonalInfoSubmitted?.id,
                  study_arm_status_title:
                    researchEnrollmentPersonalInfoSubmitted?.title,
                },
              },
            },
          };
          dispatch(setValues(newState));
          await new Promise((resolve) => setTimeout(resolve, 5000));
          await dispatch({
            type: `${accountProfileApiSlice.reducerPath}/invalidateTags`,
            payload: ['AccountProfiles', 'AccountProfile'],
          });
          navigate(getRoute(newState));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const isLoading =
    isEditingPersonAttributes || isUpdatingStudyArm || isSubmitting;

  return (
    <OnboardingLayout maxWidth='415px' backHandler={goBack}>
      <Row>
        <AVLogo />
      </Row>
      <Title>{meta?.label?.[LANG]}</Title>
      <Text marginTop='5px' marginBottom='20px'>
        Please enter YOUR information below, not the information for a child or
        adult dependent at this time.​
      </Text>
      <>
        <Box>
          <form>
            <Grid container rowSpacing={3} columnSpacing={3}>
              {fieldsMap?.map((mappedField) => {
                const {
                  type,
                  name,
                  label,
                  placeholder,
                  Component: Field,
                  disabled,
                  colSpan,
                  validation,
                  show,
                  helperText,
                  ...rest
                } = mappedField;

                const customRenderer =
                  FIELD_TYPE_RENDERERS_MAP[mappedField.name];

                if (!show) return null;
                return (
                  <Grid item xs={12} key={name}>
                    <Controller
                      name={name}
                      control={control}
                      rules={validation}
                      render={({ field }) =>
                        customRenderer ? (
                          customRenderer({
                            label,
                            placeholder,
                            name,
                            country: countryName,
                            error: errors?.[name],
                            errorLabel:
                              errors?.[name]?.message ||
                              'This field is required',
                            ...rest,
                            ...field,
                          })
                        ) : (
                          <Field
                            name={name}
                            error={errors?.[name]}
                            errorLabel={
                              errors?.[name]?.message ||
                              'This field is required'
                            }
                            fullWidth={true}
                            label={label}
                            placeholder={placeholder}
                            disabled={disabled}
                            helperText={helperText}
                            helperTextPlacement="top"
                            {...rest}
                            {...field}
                          />
                        )
                      }
                    />
                  </Grid>
                );
              })}
            </Grid>
          </form>
        </Box>
      </>
      <ButtonContainer>
        <Button
          fullWidth
          onClick={handleSubmit(onSubmit)}
          loading={isLoading}
          disabled={isLoading}
        >
          Save & continue
        </Button>
      </ButtonContainer>
    </OnboardingLayout>
  );
}
export default withStudyArmRolePermissions(
  withPersonAttributes(
    withShouldSeePersonalInformationScreen(PersonalInformationDetails)
  )
);
